<template>
  <div>
    <nav class="navbar navbar-expand-lg position-fixed top-0 w-100 z-3 fixed-top-invert">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img src="../assets/champlogo.png" alt="" class="img-fluid logo"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav ms-auto">
            <router-link class="nav-link px-lg-4" aria-current="page" to="/about">About Us</router-link>
            <router-link class="nav-link px-lg-4" to="/faq">FAQs</router-link>
            <router-link class="nav-link px-lg-4" to="#">Blog</router-link>
            <router-link class="btn btn-primary px-4 py-2 linear" to="/contact">Contact Us</router-link>
          </div>
        </div>
      </div>
    </nav>
    <section class="faq-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <img
              src="../assets/faq.62fe028e353ce5e6e784.png"
              alt=""
              class="img-fluid mb-5"
            />
            <h1 class="text-secondary fw-bold fs-2">
              Frequently asked question
            </h1>
          </div>
        </div>
        <div class="row justify-content-center pt-2">
          <div class="col-md-8">
            <p class="text-center text-muted">
              Did you come here for something in particular or just general
              Riker-bashing? And blowing
            </p>
          </div>
        </div>
        <div class="row justify-content-center accordion-section">
          <div class="col-md-8">
            <div class="accordion mt-5" id="accordionExample">
              <div class="card-accordion">
                <div class="card-header" id="headingOne">
                  <h2 class="clearfix mb-0">
                    <a
                      class="btn btn-link text-secondary fw-bolder fs-6"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      >How do I get started?

                      <span class="material-symbols-outlined add-icon">
                        add
                      </span>
                      <span class="material-symbols-outlined minus-icon">
                        remove
                      </span>
                    </a>
                  </h2>
                </div>
                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body text-muted">
                    You can get started by joining the waitlist to earn 500
                    Champ tokens for free as well as the bragging right to be
                    part of the first testers of the game which comes with
                    several rewards. Joining the waitlist also ensures you get
                    first hand information when the mobile app goes live on IOS
                    and Play Store. Once the app is available, proceed to
                    download on your mobile device and create your account.
                  </div>
                </div>
              </div>
              <div class="card-accordion">
                <div class="card-header" id="headingTwo">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link collapsed text-secondary fw-bolder fs-6"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      >How do I earn money from the App?
                      <span class="material-symbols-outlined add-icon">
                        add
                      </span>
                      <span class="material-symbols-outlined minus-icon">
                        remove
                      </span>
                    </a>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    To earn money from playing games on the app, all you need to
                    do is fund your wallet and select the amount you will be
                    staking to play the game of your choice. The app will pair
                    you with a player with an equal stake for you to earn the
                    entire stake amount once you win.
                  </div>
                </div>
              </div>
              <div class="card-accordion">
                <div class="card-header" id="headingThree">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link collapsed text-secondary fw-bolder fs-6"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      >Can I play with my friends or random players?
                      <span class="material-symbols-outlined add-icon">
                        add
                      </span>
                      <span class="material-symbols-outlined minus-icon">
                        remove
                      </span>
                    </a>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    This is solely dependent on the Stake mode you have chosen.
                    You have the option to choose or randomize with the Coin
                    stake while the Token stake is strictly randomized.
                  </div>
                </div>
              </div>
              <div class="card-accordion">
                <div class="card-header" id="headingFour">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link collapsed text-secondary fw-bolder fs-6"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      >What is the difference between Champ Coin and Champ
                      Token?
                      <span class="material-symbols-outlined add-icon">
                        add
                      </span>
                      <span class="material-symbols-outlined minus-icon">
                        remove
                      </span>
                    </a>
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  class="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    You will need either Champ Coin or Champ Token to play any
                    game on the App. Tokens are free and cannot be withdrawn.
                    However, the coin is funded and the wins from the coin stake
                    can be deposited into any bank account of your choice.
                  </div>
                </div>
              </div>
              <div class="card-accordion">
                <div class="card-header" id="headingFive">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link collapsed text-secondary fw-bolder fs-6"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      >Can I play games for the fun of it to relax?
                      <span class="material-symbols-outlined add-icon">
                        add
                      </span>
                      <span class="material-symbols-outlined minus-icon">
                        remove
                      </span>
                    </a>
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  class="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    At ChampsPlay we understand there are users who are not
                    financially motivated as an such we have the token stake
                    mode that costs the users nothing to play.
                  </div>
                </div>
              </div>
              <div class="card-accordion">
                <div class="card-header" id="headingSix">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link collapsed text-secondary fw-bolder fs-6"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      >How do I get my winnings into my account after a game?
                      <span class="material-symbols-outlined add-icon">
                        add
                      </span>
                      <span class="material-symbols-outlined minus-icon">
                        remove
                      </span>
                    </a>
                  </h2>
                </div>
                <div
                  id="collapseSix"
                  class="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    All winnings go to your secure wallet in the app. The wallet
                    accumulates all your winning and you can initiate a
                    withdrawal for the funds in the wallet to move to any bank
                    account of your choice.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center accordion-section">
          <div class="col-md-8">
            <h1 class="text-secondary fw-bold fs-2 text-center">
              Can't find an answer to your question?
            </h1>
          </div>
        </div>
        <div class="row justify-content-center accordion-section">
          <div class="col-md-8 text-center">
            <router-link class="btn btn-outline-primary py-2 px-4" to="/contact"
              >Contact Us <i class="bi bi-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import Footer from "@/components/Footer.vue";

function setUpAccordion() {
  const cards = document.querySelectorAll(".card-accordion");

  cards.forEach((btn) => {
    btn.addEventListener("click", function () {
      const collapseElement = btn.querySelector(".collapse");
      // const plusIcon = btn.querySelector(".add-icon");
      // const minusIcon = btn.querySelector(".minus-icon");
      document.querySelectorAll(".collapse").forEach((item) => {
        if (item !== collapseElement) {
          item.classList.remove("show");
          // item.parentElement.querySelector(".minus-icon").classList.add("hide");
        }
      });

      collapseElement.classList.toggle("show");

      // if (collapseElement.classList.contains("show")) {
      //   plusIcon.style.display = "none";
      //   minusIcon.style.display = "inline";
      // } else {
      //   plusIcon.style.display = "inline";
      //   minusIcon.style.display = "none";
      // }
    });
  });
}

onMounted(() => {
  setUpAccordion();
});
</script>

<style scoped>

.nav-link{
    color: #171F73 !important;
}

.logo {
  width: 70px;
}
.linear {
  background: linear-gradient(90deg, #fe417d, #4e52ca);
  border: 1px;
}

@media (min-width: 992px) {
  .nav-link {
    padding-left: 30px;
  }
}
.fixed-top-invert {
  background: #fdf2ff !important;
}
a {
  text-decoration: none;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.faq-section {
  margin-top: 190px;
}

.accordion-section {
  margin-bottom: 60px;
}

.accordion .card-accordion {
  border: none;
  border-radius: 0;
  margin-bottom: 5px;
  padding-bottom: 3px;
}

.minus-icon,
.hide {
  display: none;
}

.btn-outline-primary {
  border-color: #d6386d;
  color: #d6386d;
}

.btn-outline-primary:hover {
  background-color: #d6386d;
  border-color: #d6386d;
  color: #fff;
}

.accordion .card-header {
  background: #f5e1f9;
  border-radius: 0;
  border-radius: 8px;
  margin-bottom: 0 !important;
  margin: 0 3px 3px 0;
  padding: 1rem;
}

.accordion .card-header:hover {
  background: #e0e0e0;
}

.accordion .card-header span {
  float: right;
  font-size: 1.3rem;
  font-weight: 700;
  position: relative;
  top: 5px;
  transition: all 0.3s linear;
}

.accordion .card-header:hover span {
  transform: rotate(90deg);
}

.card-body {
  flex: 1 1 auto;
  padding: 30px 1rem;
  transition: all 0.7s ease-in-out;
}
.accordion .card-header .btn {
  font-size: 1.04rem;
  font-weight: 500;
  position: relative;
  text-align: left;
  top: -2px;
  width: 100%;
}
</style>
