<template>
  <div class="home">
    <NavBar class="position-fixed top-0 w-100 navbar-dark-theme z-3" />
    <div class="container-fluid banner-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6" data-aos="slide-right" data-aos-duration="1000">
            <h2 class="text-white banner-title mt-7">
              Earn Real Money<br />While Having Fun
            </h2>
            <h5 class="text-white pt-2 mb-5 banner-text">
              Select your best games, relax and earn real money value while you
              play.
            </h5>
            <div>
              <img
                src="../assets/playstore.png"
                alt=""
                class="img-fluid"
                width="324"
              />
            </div>
          </div>
          <div class="col-md-6">
            <img
              src="../assets/heroSectionPicture.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container">
        <div class="row justify-content-center table-section">
          <div class="col-md-10 col-lg-10 text-center coin">
            <h2 class="fw-bolder mb-5 text-secondary">
              Get free 500 Champs Tokens<br />by joining our waitlist
            </h2>
            <button
              type="button"
              class="btn btn-primary mb-5 px-5 py-3 bg-faint text-secondary border-0 fw-bold"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              
            >
              Click to join the waitlist
            </button>
            <div
              class="modal"
              id="exampleModal"
              data-bs-backdrop="false"
              aria-hidden="true"
              ref="waitlistModal"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title text-white fw-bolder"
                      id="exampleModalLabel"
                    >
                      Get free Champ Token by joining our waitlist
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="joinWailist">
                      <div class="form-group">
                        <label class="text-white fw-bold pb-2"
                          >Your full name</label
                        ><input
                          type="name"
                          class="form-control py-2"
                          id="fullname"
                          required
                          v-model="joinWailistForm.name"
                        />
                      </div>
                      <div class="form-group mt-3">
                        <label class="text-white fw-bold pb-2"
                          >Your email address</label
                        ><input
                          type="email"
                          class="form-control py-2"
                          id="email"
                          required
                          v-model="joinWailistForm.email"
                        />
                      </div>
                      <div class="form-group mt-3">
                        <label class="text-white fw-bold pb-2"
                          >Phone number</label
                        ><input
                          type="text"
                          class="form-control py-2"
                          id="phone"
                          required=""
                          v-model="joinWailistForm.phone"
                        />
                      </div>
                      <div class="mt-4">
                        <button
                          type="submit"
                          class="btn btn-primary btn-block px-4 py-2 bg-white text-secondary border-btn fw-bold"
                        >
                          <span
                            class=" "
                            role="status"
                            aria-hidden="true"
                          ></span
                          >Submit
                           <span
                              class="spinner-border spinner-border-sm"
                              v-if="loading"
                            ></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="showAlert"
              class="alert alert-light position-fixed fixed-bottom m-auto w-50 text-center fw-bold"
              style="
                z-index: 2000;
                left: 50%;
                transform: translateX(-50%);
                bottom: 25%;
              "
              role="alert"
            >
              <img
                src="../assets/check-outline.svg"
                alt=""
                class="img-fluid w-25 h-25"
              />
             <p class="mt-3">{{ message }}</p> 
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="container-fluid testimonial-section aos-init aos-animate"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-12 text-center">
            <h1 class="text-secondary fw-bold fs-2">Hear From Our Customers</h1>
          </div>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    id="testimonials"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#testimonials"
                        data-bs-slide-to="0"
                        class=""
                        aria-label="Slide 1"
                      ></button
                      ><button
                        type="button"
                        data-bs-target="#testimonials"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                        class="active"
                        aria-current="true"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div class="carousel-item">
                        <div class="row mt-5">
                          <div class="col-sm-6">
                            <div class="card mb-2">
                              <div class="row g-0">
                                <div class="col-12">
                                  <div class="card-body pb-5">
                                    <small class="text-secondary fw-bold mb-3"
                                      >February 15, 2024</small
                                    >
                                    <h3
                                      class="card-title text-secondary fw-bold fs-6"
                                    >
                                      Tunde
                                    </h3>
                                    <p class="card-text">
                                      I can’t wait to finally put my gaming
                                      skills to test and be the ultimate champ.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="row g-0">
                                <div class="col-12">
                                  <div class="card-body">
                                    <small class="text-secondary fw-bold mb-3"
                                      >March 20, 2024</small
                                    >
                                    <h3
                                      class="card-title text-secondary fw-bold fs-6"
                                    >
                                      Oluchi
                                    </h3>
                                    <p class="card-text">
                                      I love to play games and now I can earn
                                      money from doing just that. This is going
                                      to be exciting.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item active">
                        <div class="row mt-5">
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="row g-0">
                                <div class="col-12">
                                  <div class="card-body pb-5">
                                    <small class="text-secondary fw-bold mb-3"
                                      >June 4, 2024</small
                                    >
                                    <h3
                                      class="card-title text-secondary fw-bold fs-6"
                                    >
                                      Haruna
                                    </h3>
                                    <p class="card-text">
                                      Playing games and earning money will be a
                                      good way to cope with Lagos traffic.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="row g-0">
                                <div class="col-12">
                                  <div class="card-body">
                                    <small class="text-secondary fw-bold mb-3"
                                      >July 9, 2024</small
                                    >
                                    <h3
                                      class="card-title text-secondary fw-bold fs-6"
                                    >
                                      Mike
                                    </h3>
                                    <p class="card-text">
                                      I cant wait to pair with all my friends on
                                      the app and beat them one after the other.
                                      Let us put our money where our mouth is.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import { ref } from "vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import axios from "@/Plugins/axios";

const waitlistModal = ref(null);

const showAlert = ref(false);
const isModalVisible = ref(false);
const loading = ref(false);


const message = ref("");
const joinWailistForm = ref({
  name: "",
  email: "",
  phone: "",
});

function joinWailist() {
  loading.value = true;
  axios
    .post(`/waitlists`, joinWailistForm.value)
    .then((response) => {
      loading.value = false;
      waitlistModal.value.style.zIndex = '-5';
      waitlistModal.value.style.display = 'none';
      isModalVisible.value = false;
      message.value = `Thank you for joining the Wailist!`;
      showAlert.value = true;
      
      const modalInstance = new bootstrap.Modal(waitlistModal.value);
        modalInstance.hide();

      setTimeout(() => {
        showAlert.value = false;
      }, 4000);
      joinWailistForm.value.name = "";
      joinWailistForm.value.email = "";
      joinWailistForm.value.phone = "";
    })
    .catch((error) => {
      isModalVisible.value = false;
      message.value = `Error, Try Again`;
       
      showAlert.value = true;

      setTimeout(() => {
        showAlert.value = false;
      }, 7000);
    });
}
</script>

<style scoped>
.navbar-dark-theme {
  background-color: #00000040 !important;
}

.w-40 {
  width: 40% !important;
}
.banner-section {
  background-image: url(../assets/hero-bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 65vh;
  padding: 13rem 0 0;
}
.banner-title {
  font-size: 55px;
  font-weight: 700;
}
.banner-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.table-section {
  background-image: linear-gradient(#df6ad3, rgba(243, 70, 226, 0));
  border-radius: 20px;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
  margin-top: -64px;
  padding: 60px 20px 0;
  position: relative;
}
.coin {
  background-image: url(../assets/coins.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 778px 472px;
  padding: 91px 30px;
}
.mt-7 {
  margin-top: 6.5rem;
}
.text-secondary {
  color: #131a72 !important;
  opacity: 100% !important;
}

.table-section h2 {
  font-family: "Roboto", sans-serif;
  font-size: 60px;
  line-height: 70px;
}

.bg-faint {
  background-color: #f7eff8 !important;
}

/* modal */

.modal-content {
  background-clip: padding-box;
  background-color: #851643cc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 40px;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.testimonial-section {
  background-color: #fef9ff;
  margin: 6em 0 0;
  padding: 60px 10px;
}

.form-control {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ffffff70;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
}

input:focus {
  background-color: #fff;
}
.carousel-indicators {
  bottom: -54px;
}
.carousel-indicators [data-bs-target] {
  background-color: #d6386d;
}
.card {
  word-wrap: break-word;
  background-clip: initial;
  /* background-color: #fff; */
  border: 1px solid #fff;
  border-radius: 10px;
  /* display: flex;
  flex-direction: column;
  align-items: stretch; */
  /* min-width: 0; */
  /* position: relative; */
}
.card-body {
  flex: 1 1 auto;
  padding: 30px 1rem;
}
</style>
