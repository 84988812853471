<template>
  <div>
    <NavBar class="position-fixed top-0 w-100 navbar-dark-theme z-3" />

    <section class="card-section">
      <div class="container">
        <div class="py-5">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-7">
              <h1 class="text-secondary">Feedback</h1>
              <h3 class="card-subtitle fs-5 mb-3">
                Help us get better by sending feedbacks
              </h3>
            </div>
            <div class="col-12 col-lg-7">
              <div class="card p-4 bg-white main-card">
                <form @submit.prevent="submitFeedback">

                  <div class="row">
                    <div class="col-12">
                      <h5>
                        How satisfied are you with the overall experience of
                        Champs Play?
                      </h5>
                      <select name="" id="" class="form-select" v-model="feedbackForm.overall_experience">
                        <option selected disabled>Select</option>
                        <option value="Very Satisfied">Very Satisfied</option>
                        <option value="Satisfied">Satisfied</option>
                        <option value="Neutral">Neutral</option>
                        <option value="Dissatisfied">Dissatisfied</option>
                      </select>
                    </div>
                    <div class="col-12 mt-4">
                      <h5>
                        Did you encounter any issues or bugs while playing Ludo?
                      </h5>
                      <select name="" id="" class="form-select" v-model="feedbackForm.bug_in_ludo">
                        <option selected disabled>Select</option>
  
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div class="col-12 mt-4">
                      <h5>If yes please state it here.</h5>
                      <textarea
                        name=""
                        id=""
                        class="form-control"
                        placeholder="What was the issue?"
                        v-model="feedbackForm.issue_description"
                      ></textarea>
                    </div>
  
                    <div class="col-12 mt-4">
                      <h5>
                        How would you rate the ease of navigation within the app?
                      </h5>
                      <select name="" id="" class="form-select" v-model="feedbackForm.navigation_rating">
                        <option selected disabled>Select</option>
  
                        <option value="Very Easy">Very Easy</option>
                        <option value="Easy">Easy</option>
                        <option value="Difficult">Difficult</option>
                      </select>
                    </div>
  
                    <div class="col-12 mt-4">
                      <h5>
                        Do you feel the Champ Tokens system is fair and easy to
                        use?
                      </h5>
                      <select name="" id="" class="form-select" v-model="feedbackForm.token_system_fair">
                        <option selected disabled>Select</option>
  
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
  
                    <div class="col-12 mt-4">
                      <h5>
                        How likely are you to use Champs Coins for funding and
                        withdrawals when introduced?
                      </h5>
                      <select name="" id="" class="form-select" v-model="feedbackForm.use_champs_coins">
                        <option selected disabled>Select</option>
  
                        <option value="Very Likely">Very Likely</option>
                        <option value="Likely">Likely</option>
                        <option value="Neutral">Neutral</option>
                        <option value="Unlikely">Unlikely</option>
                      </select>
                    </div>
  
                    <div class="col-12 mt-4">
                      <h5>
                        What features would you like to see added in future
                        updates?
                      </h5>
                      <textarea
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Your Ideas here"
                        v-model="feedbackForm.requested_features"
                      ></textarea>
                    </div>
                    <div class="col-12 mt-4">
                      <h5>Any other comments or suggestions?</h5>
                      <textarea
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Your thoughts here"
                        v-model="feedbackForm.comments"
                      ></textarea>
                    </div>
  
                    <div class="col-12 mt-4">
                      <button class="btn btn-accent py-2" type="submit">
                        Submit Feedback
                        <span
                          class="spinner-border spinner-border-sm"
                          v-if="loading"
                        ></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            v-if="showAlert"
            class="alert alert-light position-fixed fixed-bottom m-auto w-50 text-center fw-bold"
            style="
              z-index: 2000;
              left: 50%;
              transform: translateX(-50%);
              bottom: 25%;
            "
            role="alert"
          >
            <img
              src="../assets/check-outline.svg"
              alt=""
              class="img-fluid w-25 h-25"
            />
            <p class="mt-3">{{ message }}</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import axios from "@/Plugins/axios";

const showAlert = ref(false);
const loading = ref(false);
const requestOutcome = ref(null);
const alertClasses = computed(() => {
  return requestOutcome.value === "success"
    ? "alert alert-success"
    : requestOutcome.value === "error"
    ? "alert alert-danger"
    : "";
});

const message = ref("");
const feedbackForm = ref({
  overall_experience: "",
  bug_in_ludo: false,
  issue_description: "",
  navigation_rating: "",
  token_system_fair: false,
  use_champs_coins: "",
  requested_features: "",
  comments: "",
});

function submitFeedback() {
  loading.value = true;

 feedbackForm.value.bug_in_ludo = feedbackForm.value.bug_in_ludo == "Yes" ? true : false;
  feedbackForm.value.token_system_fair = feedbackForm.value.token_system_fair == "Yes" ? true : false;

  axios
    .post(`/feedbacks`, feedbackForm.value)
    .then((response) => {
      loading.value = false;

      message.value = `Thank you for joining the Wailist!`;
      showAlert.value = true;

      setTimeout(() => {
        showAlert.value = false;
      }, 4000);
      feedbackForm.value.bug_in_ludo = "";
      feedbackForm.value.overall_experience = "";
      feedbackForm.value.bug_in_ludo = false;
      feedbackForm.value.issue_description = "";
      feedbackForm.value.navigation_rating = "";
      feedbackForm.value.token_system_fair = false;
      feedbackForm.value.use_champs_coins = "";
      feedbackForm.value.requested_features = "";
      feedbackForm.value.comments = "";
    })
    .catch((error) => {
      message.value = `Error, Try Again`;

      showAlert.value = true;

      setTimeout(() => {
        showAlert.value = false;
      }, 7000);
    });
}
</script>
<style scoped>
.navbar-dark-theme {
  background-color: #bebebf !important;
}

.card-section {
  padding: 7rem 0 0;
}
.main-card {
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
}

.btn-accent {
  background-color: #bf3865d3;
  display: block;
  width: 100%;
  border-radius: 5px;
  color: #fff;
}

.btn-accent:hover {
  background-color: #bf3865;
  color: #fff;
}
</style>
