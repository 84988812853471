import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import AboutUsPage from '../views/AboutUs.vue'
import FAQPage from '../views/FAQs.vue'
import ContactPage from '../views/ContactUs.vue'
import TermsPage from '../views/Terms.vue'
import PrivacyPage from '../views/Privacy.vue'
import FeedbackPage from '../views/Feedback.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUsPage
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQPage
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: FeedbackPage
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsPage
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
