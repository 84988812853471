<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";

onMounted(() => {
  AOS.init();
});
</script>

<style>
* {
  font-family: "Raleway", sans-serif;
}

.text-secondary {
  color: #131a72 !important;
  opacity: 100% !important;
}

body {
  background-color: #fff;
  overflow-x: hidden !important;
  /* overflow-y: hidden!important; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: "Raleway", sans-serif;
  overflow-x: hidden !important;
}

html {
  overflow-x: hidden !important;
}

@media only screen and (max-width: 768px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}
</style>
