<template>
  <div>
    <nav class="navbar navbar-expand-lg ">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img src="../assets/champlogo.png" alt="" class="img-fluid logo"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav ms-auto">
            <router-link class="nav-link px-lg-4" aria-current="page" to="/about">About Us</router-link>
            <router-link class="nav-link px-lg-4" to="/faq">FAQs</router-link>
            <router-link class="nav-link px-lg-4" to="#">Blog</router-link>
            <router-link class="btn btn-primary px-4 py-2 linear" to="/contact">Contact Us</router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script setup></script>
<style scoped>

.nav-link{
    color: #fff;
}
.logo {
  width: 70px;
}
.linear {
  background: linear-gradient(90deg, #fe417d, #4e52ca);
  border: 1px;
}

.navbar-toggler-icon {
  color: #fff !important; 
  background-image: url('../assets/hamburger.svg');
}

.navbar-toggler:focus{
  outline: none;
  border:none; 
}


@media (min-width: 992px) {
  .nav-link {
    padding-left: 30px;
  }
}
</style>
