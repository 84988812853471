<template>
  <div class="home">
    <NavBar class="position-fixed top-0 w-100 navbar-dark-theme z-3" />
    <div class="container-fluid contact-section">
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 text-contact aos-init aos-animate"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            <h2 class="text-white banner-title">
              Any Questions?<br />We are glad<br />to help
            </h2>
          </div>
          <div class="col-md-6">
            <div class="form-box">
              <form @submit.prevent="contactUs">
                <div class="mb-4">
                  <h6 class="text-white fs-5 fw-bolder">
                    Drop us a quick message, we will get back to you as soon as
                    possible
                  </h6>
                </div>
                <div class="form-group">
                  <label for="name" class="text-white fw-bold pb-1"
                    >Your Name</label
                  ><input
                    type="name"
                    class="form-control py-2"
                    id="formGroupExampleInput"
                    required
                    v-model="contactUsForm.name"
                  />
                </div>
                <div class="form-group">
                  <label for="email" class="text-white fw-bold pb-1"
                    >Your Email Address</label
                  ><input
                    type="email"
                    class="form-control py-2"
                    id="formGroupExampleInput"
                    required
                    v-model="contactUsForm.email"
                  />
                </div>
                <div class="form-group">
                  <label for="name" class="text-white fw-bold pb-1"
                    >Your Message</label
                  ><textarea
                    class="form-control"
                    name="message"
                    v-model="contactUsForm.message"
                    rows="5"
                  ></textarea>
                </div>
                <div class="mt-4">
                  <button
                    type="submit"
                    class="btn btn-primary px-4 py-2 bg-white text-secondary border-btn fw-bold"
                  >
                    Send Message
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="loading"
                    ></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showAlert"
        class="alert alert-light position-fixed fixed-bottom m-auto w-50 text-center fw-bold"
        style="
          z-index: 2000;
          left: 50%;
          transform: translateX(-50%);
          bottom: 25%;
        "
        role="alert"
      >
        <img
          src="../assets/check-outline.svg"
          alt=""
          class="img-fluid w-25 h-25"
        />
        <p class="mt-3">{{ message ? message : "" }}</p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import { ref } from "vue";

import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import axios from "@/Plugins/axios";

const loading = ref(false);
const showAlert = ref(false);
const message = ref("");

const contactUsForm = ref({
  name: "",
  email: "",
  message: "",
});

function contactUs() {
  loading.value = true;
  axios
    .post(`/contacts`, contactUsForm.value)
    .then((response) => {
      loading.value = false;

      message.value = `Thank you for Contacting Us!`;
      showAlert.value = true;

      setTimeout(() => {
        showAlert.value = false;
      }, 4000);
      contactUsForm.value.name = "";
      contactUsForm.value.email = "";
      contactUsForm.value.message = "";
    })
    .catch((error) => {
      // console.log(error);
      message.value = `Error, Try Again`;

      showAlert.value = true;

      setTimeout(() => {
        showAlert.value = false;
      }, 7000);

    });
}
</script>

<style scoped>
.navbar-dark-theme {
  background-color: #00000040 !important;
}

.contact-section {
  background-image: url(../assets/contact-bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 90px;
}
.banner-title {
  font-size: 55px;
  font-weight: 700;
}
.text-contact {
  margin-bottom: 30px;
  margin-top: 140px;
}

.form-box {
  background: #851643cc;
  border-radius: 20px;
  box-shadow: -21px -22px 0 0 #ffffff12;
  margin: auto;
  max-width: 460px;
  padding: 50px;
}

.form-control {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ffffff70;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
}

.form-group {
  padding: 0.4rem 0;
}
input:focus,
textarea:focus {
  background-color: #fff;
}

.border-btn {
  border-color: #fff !important;
}

.text-secondary {
  color: #131a72 !important;
  opacity: 100% !important;
}
</style>
