<template>
  <div>
    <footer class="footer-section">
      <div class="site-section">
        <div class="container">
          <div class="row">
            <div class="col-sm mb-3">
              <img
                src="../assets/champlogo.png"
                alt=""
                class="img-fluid"
                width="60px"
              />
            </div>
            <div class="col-sm mb-3">
              <h3>Company</h3>
              <ul class="list-unstyled links">
                <li><router-link to="/about">About Us</router-link></li>
                <li><router-link to="/terms">Terms and Conditions</router-link></li>
                <li><router-link to="/privacy">Privacy Policy</router-link></li>
              </ul>
            </div>
            <div class="col-sm mb-3">
              <h3>Support</h3>
              <ul class="list-unstyled links">
                <li><a href="/">Blog</a></li>
                <li><router-link to="/faq">FAQs</router-link></li>
                <li><router-link to="/feedback">Feedback</router-link></li>

              </ul>
            </div>
            <div class="col-sm mb-3">
              <h3>Contact Us</h3>
              <ul class="list-unstyled links">
                <li><a href="tel:09044000008">09044000008</a></li>
                <li><a href="mailto:hello@champsplay.com">hello@champsplay.com</a></li>
              </ul>
            </div>
            <div class="col-md-3 mb-3">
              <h3>Socials</h3>
              <ul class="list-unstyled social">
                <li>
                  <a
                    href="https://www.instagram.com/champsplay/"
                    target="_blank"
                    rel="noreferrer"
                    ><i class="bi bi-instagram pe-3"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/champsplayng"
                    target="_blank"
                    rel="noreferrer"
                    ><i class="bi bi-twitter pe-3"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/Champsplay"
                    target="_blank"
                    rel="noreferrer"
                    ><i class="bi bi-facebook pe-3"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/champs-play/"
                    target="_blank"
                    rel="noreferrer"
                    ><i class="bi bi-linkedin pe-3"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mt-5">
            <div class="col">
              <ul class="list-unstyled copyright, .footer-section  d-flex">
                <li class="pe-5">© {{ currentYear }} Champ Play</li>
                <li class="pe-5">Terms</li>
                <li class="pe-5">Privacy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
const currentYear = new Date().getFullYear();
</script>
<style scoped>
.footer-section {
  position: relative;
  color: #fff;
  padding: 4rem 0 1em 0;
  background-image: url('../assets/bg-footer.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer-section .container {
  position: relative; 
}
 
.footer-section h3 {
  font-size: 15px;
  margin-bottom: 10px;
  opacity: 60%;
  margin-top: 0;
  line-height: 1.5; 
  font-family: "Poppins", sans-serif !important;
}
  
.footer-section .links li {
  margin-bottom: 13px;
  line-height: 1.5;
  display: block; 
  font-size: 13px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.footer-section .links li a {
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.footer-section .links li a:hover {
  color: #fff; 
}

.footer-section .social li {
  display: inline-block;
  position: relative; 
}
  
.footer-section .copyright{
  color: #fff;
  opacity: 50%; 
}
 

.footer-section .cta h2, .footer-section .cta h3 {
  line-height: 1.5; 
}

.footer-section .cta h3 {
 font-size: 20px; 
}
</style>
